import logo from './rarecandy.png';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Building the Metaverse.
          <br/>
          Coming soon.
        </p>
          <div className="socials">
<a href="https://twitter.com/rarecandyai" target="_blank" rel="noreferrer noopener">
            <i className="fa fa-twitter"></i>
</a>
<a href="https://medium.com/@rarecandyai" target="_blank" rel="noreferrer noopener">
            <i className="fa fa-medium"></i>
</a>
<a href="https://instagram.com/rarecandyai" target="_blank" rel="noreferrer noopener">
            <i className="fa fa-instagram"></i>
</a>
        </div>
      </header>
    </div>
  );
}

export default App;
